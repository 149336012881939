import { Component, Inject, OnInit, Renderer2 } from "@angular/core";
import { DOCUMENT } from "@angular/common";

import { provideFirebaseApp, initializeApp } from '@angular/fire/app';
import { provideMessaging, getMessaging, onMessage, Messaging } from '@angular/fire/messaging';

import { AuthService, MessagingService } from "./services";
import {
  VCFO_ORGANIZATION_ID,
  QUPIL_ORGANIZATION_ID,
  ORGANIZATION_ID,
  QUPIL_LANGUAGE_EN,
  VCFO_LANGUAGE_EN,
} from "./constants";
import { Router, NavigationEnd } from "@angular/router";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  private messaging;
  constructor(
    private message: MessagingService,
    private _renderer2: Renderer2,
    @Inject(DOCUMENT) private _document: Document,
    private authService: AuthService,
    private router: Router
  ) {}

  async ngOnInit() {
   // Initialize messaging instance
   this.messaging = getMessaging();
    
    // Organization and language setup logic
    const baseUrl = window.location.origin;
    if (
      baseUrl.includes("vcfo") &&
      this.authService.getOrganizationId() !== VCFO_ORGANIZATION_ID
    ) {
      this.authService.setOrganizationId(VCFO_ORGANIZATION_ID);
      if (!localStorage.getItem("en")) this.authService.setLanguage("en", VCFO_LANGUAGE_EN);
      window.location.reload();
    } else if (
      !baseUrl.includes("vcfo") &&
      this.authService.getOrganizationId() !== QUPIL_ORGANIZATION_ID
    ) {
      this.authService.setOrganizationId(QUPIL_ORGANIZATION_ID);
      if (!localStorage.getItem("en")) this.authService.setLanguage("en", QUPIL_LANGUAGE_EN);
      window.location.reload();
    }

    // Request messaging permissions and subscribe to messaging service
    if (this.authService.getOrganizationId() == QUPIL_ORGANIZATION_ID) {
      this.message.requestPermission();
    }

        // Listen for incoming messages using the updated method
        onMessage(this.messaging, (payload) => {
          console.log("Message received: ", payload);
    });

    // Get images, languages, and icons
    await this.authService.getLogoAndImages(ORGANIZATION_ID).toPromise();
    await this.authService.getLanguages(ORGANIZATION_ID).toPromise();

    var link = document.createElement("link");
    link.setAttribute("rel", "icon");
    link.setAttribute("type", "image/x-icon");
    link.setAttribute("href", localStorage.getItem("logo"));
    document.head.appendChild(link);
  }
}
