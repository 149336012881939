import { Injectable } from "@angular/core";
import { Router } from "@angular/router";

import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
} from "@angular/router";
import { Observable } from "rxjs";
import { filter, map } from "rxjs/operators";

import { Store, select } from "@ngrx/store";

import * as fromStore from "../store";
import * as fromModels from "../models";

@Injectable({
  providedIn: "root",
})
export class ENGINEERGuard implements CanActivate {
  constructor(
    private router: Router,
    private store: Store<fromStore.IRootState>
  ) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.store.pipe(
      select(fromStore.getCurrentUser),
      filter((user: fromModels.IUser) => !!user),
      map((user: fromModels.IUser) => {
        if (user?.isEmailVerified) {
          if (
            user?.organizations[0]?.role?.type === "OWNER" ||
            user?.organizations[0]?.role?.type === "ADMIN" ||
            user?.organizations[0]?.role?.type === "ENGINEER" 
          ) {
            return true;
          } else if (user?.organizations[0]?.role?.type === "TEACHER") {
            this.router.navigate(["/teacher"]);
            return false;
          } else if (user?.organizations[0]?.role?.type === "MEMBER") {
            this.router.navigate(["/member"], {
              queryParams: { action: "login" },
            });
            return false;
          }
        } else {
          this.router.navigate(["/auth/signup"]);
          return false;
        }
      })
    );
  }
}
